@import '../../styles/variables.scss';

.nav {
  @include transition;
  @include title-2;
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: center;
  top:0px;
  left:0px;
  z-index: 0;
  transform: translateY(-80px);
  background-color: $white;
  height: 80px;
  box-shadow: $card-shadow;
  &.nav-is-active {
    transform: translateY(0px);
    box-shadow: $card-shadow;
  }
  .content {
    @include content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      text-decoration: none;
      color: $dark;
    }
    .logo {
      display: flex;
      h2 {
        @include title-2;
        margin-left: 10px;
      }
      img {
        object-fit: contain;
      }
    }
    .links {
      display: none;
      text-transform: uppercase;
      @media only screen and (min-width: $md-screen) {
        display: block;
      }
      a {
        @include transition;
        padding: 10px 20px;
        border-radius: $radius;
        color: rgba($dark , 0.5);
        text-decoration: none;
        &.is-active {
          color: $dark;
        }
      }
    }
    .icons {
      display: flex;
      justify-content: flex-end;
      button {
        @include btn;
        position: relative;
      }
    }
    .mobile-toggle {
      display: block;
      @media only screen and (min-width: $md-screen) {
        display: none;
      }
    }
    .logout {
      display: none;
      justify-content: flex-end;
      margin-left: 20px;
      @media only screen and (min-width: $md-screen) {
        display: flex;
      }
    }
  }

}


