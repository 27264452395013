@import '../../styles/variables';

.container {
  width: 100%;
  .btn {
    @include transition;
    display: block;
    margin: 5px 0px;
    cursor: pointer;
    text-transform: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px;
    font-size: 14px;
    color: $primary-color;
    border-radius: 3px;
    text-decoration: underline;
    svg {
      margin-right: 15px;
    }
  }
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .error-message {
    margin: 0px;
    color: $danger;
    min-height: 20px;
    padding-top: 3px;
    font-size: 12px;
  }
  .loader {
    display: flex;
    svg {
      animation:spin 2s linear infinite;
    }
  }
  &.doctor-card {
    label {
      font-weight: bold;
    }
    .btn {
      color: $primary-color;
      margin-top: 20px;
      font-weight: normal;
      &:hover {
        background-color: rgba($primary-color,0.15);
      }
    }
  }
  @keyframes spin {
    100% { transform:rotate(360deg)} 
  }
}