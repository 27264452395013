@mixin page-container {
  position: absolute;
  left: 0;
  width: 100vw;
  z-index: -1;
  height: 100vh;
  display: flex;
  justify-content: center;
  opacity: 1;
}

@mixin content {
  position: relative;
  width: 90%;
  @media only screen and (min-width: $md-screen) {
    width: 96%;
  }
  @media only screen and (min-width: $lg-screen) {
    width: 90%;
  }
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}



@mixin animation-mixin-x($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateX($from); opacity: 0;}
    100% {transform: translateX($to); opacity: 1;}
  }
}

@mixin animation-scale($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: scale($from); opacity: 0;}
    100% {transform: scale($to); opacity: 1;}
  }
}

@mixin animation-mixin-y($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateY($from); opacity: 0;}
    100% {transform: translateY($to); opacity: 1;}
  }
}

@mixin animation-fade($name) {
  @keyframes #{$name} {
    0% { opacity: 0;}
    100% { opacity: 1;}
  }
}

@mixin btn {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  outline: none;
  border: none;
}

@mixin box {
  @include transition;
  background: #FFFFFF;
  box-shadow: 0px 10px 28px #EBECED;
  border-radius: 3px;
  margin-bottom: 20px;
}

@mixin page-container {
  position: absolute;
  top: 80px;
  width: 100%;
  height: calc(100vh - 80px);
}


@mixin animation-scale($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: scale($from); opacity: 0;}
    100% {transform: scale($to); opacity: 1;}
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($primary-color, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
}

@mixin scrollbarX {
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #ECECF0;
  }
  &::-webkit-scrollbar-thumb {
    background: #405C94;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $dark;
  }
}
