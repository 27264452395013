.checkbox{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  label{
    margin-left: 10px;
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 12px;
    cursor: pointer;
  }
}
