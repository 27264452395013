@import '../../styles/variables.scss';

.btn-container {
  position: absolute;
  top: 55px;
  right: 5%;
  z-index: 300;
  display: flex;
  a {
    text-decoration: none;
  }
}

.container {
  @include page-container;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  .list-toggle {
    margin-top: 20px;
    button {
      @include btn;
      @include text;
      padding: 8px 15px;
      background: rgba($primary-color, 0.4);
      box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.1);
      color: $white;
      &:first-child {
        border-bottom-left-radius: $radius;
        border-top-left-radius: $radius;
        border-right: 1px solid rgba(208, 102, 102, 0.2);
      }
      &:last-child {
        border-left: 1px solid rgba(208, 102, 102, 0.2);
        border-bottom-right-radius: $radius;
        border-top-right-radius: $radius;
      }
      &:hover {
        background: rgba($primary-color, 0.7);
      }&.active {
        background: $primary-color;
      }
    }
  }
  .content {
    @include content;
    padding-bottom: 60px;
    margin-top: 20px;
  }
}