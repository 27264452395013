@import '../../styles/variables.scss';

.edit-toggle {
  position: absolute;
  top: 55px;
  right: calc(5% + 10px);
  z-index: 300;
}

.container {
  @include page-container;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  .content {
    @include content;
    height: auto;
    padding-bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 60px 0px;
    aside {
      width: 25%;
      padding-right: 30px;
      label {
        @include label;
        display: inline-block;
        margin-bottom: 5px;
        font-weight: 400;
        color: rgba($dark , 0.5);
      }
      .dates {
        .error {
          margin-top: 0px;
        }
        .date {
          width: 100px;
          text-align: center;
          margin: 0px 0px 15px 0px;
          padding: 10px;
          color: $primary-color;
          background-color: $white;
          border-radius: $radius;
          border: 1px solid $primary-color;
        } 
      }
      .link {
        display: flex;
        align-items: center;
        color: $primary-color;
        text-decoration: none;
        margin-bottom: 20px;
        transform: translateX(-10px);
        @include title-2;
        svg {
          @include transition;
        }
        &:hover {
          svg {
            transform: translateX(-5px);
          }
        }
      }
      hr {
        margin: 30px 0px;
        border-top: 1px solid #E4EAEE;
      }
      .archived {
        margin-bottom: 8px;
        pointer-events: none;
        &.edit {
          pointer-events: all;
        }
      }
      .duplicate {
        @include btn;
        @include text;
        padding: 0px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        color: rgba($dark , 0.5);
        // text-decoration: underline;
        svg {
          margin-right: 10px;
        }
      }
      .delete {
        @include btn;
        @include text;
        padding: 0px;
        display: flex;
        align-items: center;
        color: #DC6541;
        // text-decoration: underline;
        svg {
          margin-right: 10px;
        }
      }
    }
    main {
      width: 75%;
      @include box;
    }
  }
}