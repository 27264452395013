@import '../../HooksFormFields/form-mixin.scss';
.date-picker{

  label{
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.container-input{
      margin-top: 10px;
    }
  }

  .container-input{
    position: relative;
    width: 100%;
    .container-icon{
      display: block;
      position: absolute;
      top: 0px;
      right:0;
      height: 36px;
      width: 36px;
      pointer-events: none;
      color: $primary-color;
      >svg{
        position: absolute;
        top:50%;
        left:50%;
        font-size: 24px;
        transform: translate(-50%,-50%);
      }
    }

    >div{
      width: 100%;
    }
  }

  .day{
    color : black;

    &.selected{
      background-color: $primary-color;
      color : white;
    }

    &:not(.selected){
      background-color: transparent;
    }
  }

  input{
    @include input;
    border-radius: $radius;
    padding: 10px 10px;
    min-width: 100px;
    background: $white;
    outline: none;
    border: 1px solid $primary-color;
    box-shadow: 0 0 0 1px transparent;
    color: $primary-color;
    @include label;
    &:focus{
      outline: none;
    }

    &:focus{
      box-shadow: 0 0 0 1px $primary-color;
      border: 1px solid $primary-color;
      outline: none;
    }

    &::placeholder {
      font-size: 14px;
      color : #8c8c8c;
    }
  }

  &.secondary {
    input {
      border: 1px solid rgba($dark, .5);
      color: $dark;
      &:focus{
        box-shadow: 0 0 0 1px $dark;
        outline: none;
      }
    }
    svg {
      color: rgba($dark, .5);
    }
  }

}
