@import '../../../styles/variables.scss';

@mixin icon {
  @include transition;
  position: relative;
  width: 16px;
  height: 16px;
  // border: 1px solid $primary-color;
  background-color: #667ED0;
  box-shadow: 0px 10px 28px #EBECED;
  border-radius: 1px;
  z-index: 2;
  transform: rotate(45deg);
  cursor: pointer;
  &:hover {
    transform: scale(1.2) rotate(45deg);
  }
}

.step {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  padding-top: 42px;
  font-size: 12px;
  a {
    color:rgba($dark , 0.5);
    text-transform: uppercase;
    &:hover {
      color: $primary-color;
    }
  }
  &:hover {
    .tooltip {
      opacity: 1;
      top: 60px;
    }
  }
  .date {
    @include icon;
    &.same-date {
      transform: scale(1.2) rotate(45deg);
    } 
  }
  .end {
    @include icon;
    background-color: #FF8C4B;
  }
  .planned {
    @include icon;
    background-color: #69e4bf;
    box-shadow: 0px 2px 3px 3px rgba(0,0,0,0.15);
    &.same-date {
      transform: scale(1.2) rotate(45deg);
    }
  }
  .duration {
    background-color: #F0BFB4;
    width: 100%;
    height: 22px;
    transform: translateY(-3px);
    z-index: -1;
  }
  .duration-line {
    background-color: #667ED0;
    width: 100%;
    height: 1px;
    transform: translateY(8px);
    z-index: -1;
  }
  .tooltip {
    position: absolute;
    @include box;
    opacity: 0;
    pointer-events: none;
    top: 90px;
    z-index: 3;
    width: 120px;
    padding: 5px;
    text-align: center;
    &.clock {
      width: 180px;
      text-transform: uppercase;
    }
    p {
      position: relative;
      margin: 0px;
      &:not(:first-child) {
        border-top: 1px solid rgba($dark , 0.2);
        margin-top: 5px;
        z-index: 300;
        padding-top: 5px;
        margin-bottom: 5px;
      }
      label {
        color: rgba($dark , 0.5);
        font-weight: bold;
        @include label;
      }
    }
  }
  a {
    min-width: 180px;
    text-align: center;
    transform: translateY(-50px);
    font-weight: bold;
    color: rgba($dark , 0.5);
  }
}