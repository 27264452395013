@import '../../styles/variables.scss';

.header {
  position: absolute;
  top: 110px;
  width: 100%;
  z-index: 105;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  .legend {
    display: flex;
    z-index: 100;
    padding-right: 20px;
    background-color: rgba($bg-color, 0.7);
    border-radius: $radius;
    .item {
      display: flex;
      align-items: center;
      .icon {
        width: 13px;
        height: 13px;
        background-color: #667ED0;
        box-shadow: 0px 10px 28px #EBECED;
        border-radius: 1px;
        z-index: 2;
        margin: 0px 10px 0px 20px;
        transform: rotate(45deg);
        &.validity {
          background-color: #FF8C4B;
        }
        &.planned {
          background-color: #69e4bf;
        }
      }
      p {
        color:rgba($dark, 0.6);
        margin: 10px 0px
      }
    }
  }

  .list-toggle {
    button {
      @include btn;
      @include text;
      padding: 8px 15px;
      background: #DC9292;
      box-shadow: 0px 4px 20px rgba(25, 34, 67, 0.1);
      color: $white;
      &:first-child {
        border-bottom-left-radius: $radius;
        border-top-left-radius: $radius;
        border-right: 1px solid rgba(208, 102, 102, 0.2);
      }
      &:last-child {
        border-left: 1px solid rgba(208, 102, 102, 0.2);
        border-bottom-right-radius: $radius;
        border-top-right-radius: $radius;
      }
      &:hover {
        background: #DC9292;
      }&.active {
        background: $primary-color;
      }
    }
  }
}

.container {
  @include page-container;
  .content {
    position: relative;
    width: 100%;
    margin-top: 130px;
    height: calc(100% - 130px);
    overflow: auto;
    @include scrollbar;
    @include scrollbarX;
    .dates {
      position: fixed;
      height: 100vh;
      z-index: 0;
      pointer-events: none;
      transform: translateX(368px);
      display: flex;
      .month {
        margin-top: 0px;
        min-height: calc(130% - 130px);
        border-left: 1px solid rgba($dark, 0.2);
        p {
          font-weight: bold;
          text-transform: uppercase;
          color:rgba($dark, 0.6);
          width: 100%;
          text-align: center;
          margin: 0px;
          padding: 5px;
          transform: translate(-50% , -150%);
        }
        &.is-active {
          border-left: 2px dashed rgba($primary-color, 1 );
          p {
            background-color: $primary-color;
            padding: 5px;
            border-radius: 20px;
            color: $white;
          }
        }
      }
    }
    .plannings {
      width: 100%;
    }
  }
}