@import '../../../../styles/variables.scss';
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 30px 30px 30px;
  .participants {
    border-top: 1px solid #bec4c9;
    width: 100%;
    .row {
      display: flex;
      align-items: center;
      padding: 5px 5px;
      &:not(.label) {
        p {
          margin: 8px;
        }
        &:nth-child(even) {
          background-color: rgba($dark, 0.08);
          border-radius: 3px;
        }
      }
      input {
        margin: 5px 0px;
      }
      .col {
        flex: 1;
        display: flex;
        &.date {
          .input-date {
            width: 150px;
          }
          button {
            margin-left: 10px;
          }
        }
        &.present {
          justify-content: flex-end;
          padding-right: 5px;
        }
        &.delete {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          button {
            @include btn;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: rgba($dark, 0.6);
            &:hover {
              color: $danger;
            }
          }
        }
      }
    }
    .form {
      display: flex;
      margin-top: 20px;
      .col {
        flex: 1;
        padding-right: 10px;
      }
    }
    .add {
      @include btn;
      @include text;
      margin-top: 20px;
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $radius;
      background-color: $primary-color;
      color: $white;
    }
    .submit {
      @include btn;
      height: 38px;
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $radius;
      background-color: $primary-color;
      opacity: 0.4;
      pointer-events: none;
      color: $white;
      &.active {
        opacity: 1;
        pointer-events: all;
      }
      &.present {
        opacity: 1;
        pointer-events: all;
        background-color: $success;
      }
      &:hover {
        background-color: $success;
      }
      &.edit {
        background-color: rgba($dark, 0.6);
        pointer-events: all;
        &:hover {
          background-color: $success;
        }
      }
    }
  }
}