@import '../../../../styles/variables.scss';
@import '../../../../lib/HooksFormFields/form-mixin.scss';
.container {
  width: 100%;
  .add {
    @include btn;
    margin: 0px;
    font-family: "Ubuntu-Regular";
    font-size: 14px;
    text-decoration: underline;
    color: rgba(25, 34, 67, 0.5);
  }
  .links {
    .link {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: $primary-color;
      padding: 6px;
      background-color: rgba($primary-color , 0.2);
      border-radius: $radius;
      cursor: pointer;
      a {
        color: $dark;
        overflow: hidden;
        max-width: calc(100% - 30px);
      }
      button {
        @include btn;
        display: flex;
        color: $primary-color;
        justify-content: center;
        align-items: center;
        &:first-child {
          padding: 0px;
          color: $dark;
          font-size: 14px;
          p {
            margin: 5px;
            font-family: "Ubuntu-Regular";
            font-size: 14px;
            text-decoration: underline;
          }
        }
      }
    }
  }
  h2 {
    margin-top: 0px;
    font-size: 16px;
    font-weight: bold;
  }
  .input {
    margin-top: 10px;
    width: 100%;
    input {
      @include input;
      min-height: 34px;
      min-width: 100%;
      background: #F3F3F3;
      border: 1px solid #BBBBBB;
      border-radius: 3px;
      color: $black;
      @include label;
      &:focus {
        border: 1px solid $primary-color;
      }
    }
  }
}