@import '../../../styles/variables.scss';

.container-custom-date-picker{

  .react-datepicker__triangle{
    // border-bottom-color : #d2d2d2;
  }

  .react-datepicker__navigation{
    border: none;
    height: 20px;
    width: 20px;
    &:nth-child(3){
      transform: scaleX(-1);
    }

    &-icon--next {
      display: none;
    }

    &-icon--previous {
      display: none;
    }

    &:before,
    &:after{
      position: absolute;
      z-index: 2;
      content:"";
      top: 10px;
      display: block;
      width: 10px;
      height: 2px;
      background-color: $primary-color;
    }

    &:after{
      transform: translateY(3px) rotate(45deg);
    }
    &:before{
      transform: translateY(-3px) rotate(-45deg);
    }
  }

  .react-datepicker__header{
    background-color: white;
    border-bottom: 1px solid #d2d2d2;

    .react-datepicker__current-month{
      line-height: 20px;
      color : $primary-color;
    }
  }

}

.react-datepicker-popper {
  transform:  translateY(40px);
}