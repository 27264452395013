@import '../../styles/variables.scss';

.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  color: $dark;
  &.label {
    max-height: 40px;
  }
  &:not(.label) {
    height: 60px;
    @include box;
    .name {
      @include bold
    }
    &:hover {
      box-shadow: 0px 10px 20px #d3d3d3;
    }
  }
  .updated {
    position: absolute;
    top: 24px;
    left: -6px;
    width: 13px;
    height: 13px;
    color: $white;
    background-color: $primary-color;
    border-radius: 12px;
  }
  .col {
    width: calc((100% - 350px) / 4 );
    display: flex;
    align-items: center;
    padding: 0px 20px;
    &.name {
      width:  350px;
      padding: 10px 10px 10px 30px;
    }
    .present {
      margin-left: 10px;
      padding-top: 2px;
      color: rgba($dark, 0.2);
      &.active {
        color: $success;
      }
    }
  }   
}