@import '../../styles/variables.scss';

.container {
  margin-bottom: 20px;
  position: relative;
  .training {
    position: relative;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
  }
}