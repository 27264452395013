@import '../../styles/variables.scss';

.container {
  margin-top: 20px;
  .name {
    @include btn;
    @include text;
    @include bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    &:hover {
      color: $primary-color;
    }
    .icon {
      svg {
        @include transition;
      }
      &.open {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    p {
      @include transition;
      margin: 0px 5px;
    }
  }
  .content {
    @include transition;
    overflow: hidden;
    padding: 0px 10px;
    margin-bottom: 10px;
  }
}