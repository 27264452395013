@import '../../../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 30px 30px 30px;
  label {
    @include label;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 400;
    color: rgba($dark , 0.5);
    &:not(:first-child) {
      margin-top: 15px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    .name {
      width: 100%;
      h3 {
        margin: 0px;
        @include title-3;
      }
    }
    .url {
      margin-top: 20px;
    }
    .row {
      width: 100%;
      display: flex;
      margin-top: 20px;
      &.border {
        border-top: 1px solid #bec4c9;
        padding-top: 20px;
      }
      .col {
        flex: 1;
        padding-right: 10px;
        &:not(:first-child) {
          border-left: 1px solid #bec4c9;
          padding-left: 10px;
        }
        &:last-child {
          border-left: 1px solid #bec4c9;
          padding-right: 0px;
        }
        p {
          @include bold;
          margin: 0px;
          &.desc {
            @include text;
          }
        }
      }
      &.block {
        display: block;
        &.product {
          display: flex;
        }
        .col {
          width: 50%;
          border-left: 1px solid transparent;
          p {
            color: $primary-color;
            padding: 6px 10px;
            background-color: rgba($primary-color , 0.2);
            border-radius: $radius;
          }
          a {
            text-decoration: none;
            p {
              display: flex;
              align-items: center;
              &:hover {
                background-color: rgba($primary-color , 0.4);
              }
              span {
                margin-left: 25px;
              }
              svg {
                position: absolute;
                margin-left: 0px;
              } 
            }  
          }
          .link {
            @include transition;
            width: calc(100% - 10px);
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            color: $primary-color;
            padding: 6px;
            background-color: rgba($primary-color , 0.2);
            border-radius: $radius;
            overflow: hidden;
            &:hover {
              background-color: rgba($primary-color , 0.3);
            }
            svg {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}