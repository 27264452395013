@import '../../styles/variables.scss';

.container {
  z-index: 102;
  .content {
    @include transition;
    padding: 0px;
    margin-bottom: 0px;
    position: relative;
    .planning {
      @include transition;
      position: absolute;
      overflow: hidden;
      padding-top: 30px;
      padding-bottom: 30px;
      transform: translateX(360px);
      opacity: 0;
    }
    .group {
      position: absolute;
      z-index: 102;
      left: 0;
      top: 0px;
      margin-top: 0px;
      padding-top: 10px;
      padding-bottom: 20px;
      padding-left: 20px;
      max-width: 300px;
      .name {
        @include box;
        width: 280px;
        padding: 20px;
        font-weight: bold;
      }
    }  
  }
}